import store from "@/store";
import moment from "moment";

export default class TrainingRequest {
  constructor() {
    this.id = null;
    this.requestDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");
    this.training = null;
    this.employee = store.state.auth.user.id;
    this.detail = null;
  }
}
